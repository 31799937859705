<template>
  <div class="inner_max my-5">
    <h2 class="text-white mb-3">Pagination</h2>
    <el-pagination :page-size="20" :pager-count="11" layout="prev, pager, next" :total="1000"> </el-pagination>
    <div class="mt-5">
      <h2 class="text-white mb-3">Modal</h2>
      <el-button @click="dialogVisible = true">click to open the Dialog</el-button>
      <el-dialog title="Tips" :visible.sync="dialogVisible" width="30%">
        <span>This is a message</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="dialogVisible = false">Confirm</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="mt-5">
      <h2 class="text-white mb-3">Input & Select</h2>
      <el-input class="mb-3" placeholder="Please input" v-model="input"></el-input>
      <el-input class="mb-3" placeholder="Please input" v-model="input" :disabled="true"></el-input>
      <el-select class="mb-3" v-model="value" placeholder="Select">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <el-select class="mb-3" v-model="value1" multiple placeholder="Select">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <el-select class="mb-3" v-model="value" placeholder="Select" disabled>
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <el-date-picker v-model="value3" type="date" placeholder="Pick a day"> </el-date-picker>
      <el-date-picker
        v-model="value1"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"
      >
      </el-date-picker>
    </div>
    <div class="mt-5">
      <h2 class="text-white mb-3">Radio & Checkbox</h2>
      <div class="mt-4">
        <el-radio v-model="radio" label="1">Option A</el-radio>
        <el-radio v-model="radio" label="2">Option B</el-radio>
        <el-radio disabled v-model="radio" label="1">Option A</el-radio>
        <el-radio disabled v-model="radio" label="3">Option B</el-radio>
      </div>
      <div class="mt-4">
        <el-checkbox v-model="checked">Option</el-checkbox>
        <el-checkbox v-model="checked1" disabled>Option</el-checkbox>
        <el-checkbox v-model="checked2" disabled>Option</el-checkbox>
      </div>
    </div>
    <div class="mt-5">
      <h2 class="text-white mb-3">Button</h2>
      <el-row class="mb-3">
        <el-button>Default</el-button>
        <el-button type="primary">Primary</el-button>
        <el-button type="success">Success</el-button>
        <el-button type="info">Info</el-button>
        <el-button type="warning">Warning</el-button>
        <el-button type="danger">Danger</el-button>
      </el-row>
      <el-row class="mb-3">
        <el-button plain>Plain</el-button>
        <el-button type="primary" plain>Primary</el-button>
        <el-button type="success" plain>Success</el-button>
        <el-button type="info" plain>Info</el-button>
        <el-button type="warning" plain>Warning</el-button>
        <el-button type="danger" plain>Danger</el-button>
      </el-row>
      <el-row class="mb-3">
        <el-button disabled>Default</el-button>
        <el-button type="primary" disabled>Primary</el-button>
        <el-button type="success" disabled>Success</el-button>
        <el-button type="info" disabled>Info</el-button>
        <el-button type="warning" disabled>Warning</el-button>
        <el-button type="danger" disabled>Danger</el-button>
      </el-row>
      <el-row>
        <el-button plain disabled>Plain</el-button>
        <el-button type="primary" plain disabled>Primary</el-button>
        <el-button type="success" plain disabled>Success</el-button>
        <el-button type="info" plain disabled>Info</el-button>
        <el-button type="warning" plain disabled>Warning</el-button>
        <el-button type="danger" plain disabled>Danger</el-button>
      </el-row>
    </div>
    <div class="mt-5">
      <el-card class="mb-4">
        <h2 class="mb-3">Card Form Elements</h2>
        <el-input class="mb-3" placeholder="Please input" v-model="input"></el-input>
        <el-input class="mb-3" placeholder="Please input" v-model="input" :disabled="true"></el-input>
        <el-select class="mb-3" v-model="value" placeholder="Select">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-select class="mb-3" v-model="value1" multiple placeholder="Select">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-select class="mb-3" v-model="value" placeholder="Select" disabled>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-date-picker v-model="value3" type="date" placeholder="Pick a day"> </el-date-picker>
        <el-date-picker
          class="mb-3"
          v-model="value1"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
        >
        </el-date-picker>
        <div class="mt-4">
          <el-radio v-model="radio" label="1">Option A</el-radio>
          <el-radio v-model="radio" label="2">Option B</el-radio>
          <el-radio disabled v-model="radio" label="1">Option A</el-radio>
          <el-radio disabled v-model="radio" label="3">Option B</el-radio>
        </div>
        <div class="mt-4">
          <el-checkbox v-model="checked">Option</el-checkbox>
          <el-checkbox v-model="checked1" disabled>Option</el-checkbox>
          <el-checkbox v-model="checked2" disabled>Option</el-checkbox>
        </div>
        <el-button class="mt-4">Default</el-button>
      </el-card>
    </div>
    <div class="mt-5">
      <h2 class="text-white mb-3">Tooltip</h2>
      <el-tooltip class="item" effect="dark" content="Top Left prompts info" placement="top-start">
        <el-button>top-start</el-button>
      </el-tooltip>
    </div>
    <div class="mt-5">
      <h2 class="text-white mb-3">Notification</h2>
      <el-button plain @click="open1"> Top Right </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'demo',
  data() {
    return {
      dialogVisible: false,
      input: '',
      options: [
        {
          value: 'Option1',
          label: 'Option1'
        },
        {
          value: 'Option2',
          label: 'Option2'
        },
        {
          value: 'Option3',
          label: 'Option3'
        },
        {
          value: 'Option4',
          label: 'Option4'
        },
        {
          value: 'Option5',
          label: 'Option5'
        }
      ],
      value: '',
      value1: [],
      value3: '',
      radio: '1',
      checked: true,
      checked1: false,
      checked2: true,
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: 'Please input Activity name', trigger: 'blur' },
          { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        region: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
        date1: [{ type: 'date', required: true, message: 'Please pick a date', trigger: 'change' }],
        date2: [{ type: 'date', required: true, message: 'Please pick a time', trigger: 'change' }],
        type: [
          { type: 'array', required: true, message: 'Please select at least one activity type', trigger: 'change' }
        ],
        resource: [{ required: true, message: 'Please select activity resource', trigger: 'change' }],
        desc: [{ required: true, message: 'Please input activity form', trigger: 'blur' }]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    open1() {
      this.$notify({
        title: 'Custom Position',
        message: "I'm at the top right corner",
        duration: 0
      });
    }
  }
};
</script>
